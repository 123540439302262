ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  padding: 0.5em 0;
  color: #d1d1d1;
}

/* Skills-specific styles with more specific selectors */
.skills-section .skill-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.skills-section .skill-list li {
  padding: 0.5em 0;
  color: #4a4a4a;
  font-size: 0.95rem;
  line-height: 1.5;
  margin: 0;
}

.skills-section .skill-list li strong {
  color: #333;
  font-weight: 600;
  margin-right: 0.5em;
}

.skills-section {
  padding: 4rem 0;
  background-color: #fcfcfc;
}

.skills-section .section-title {
  text-align: center;
  color: #000000;
  font-size: 2.25rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.skills-intro {
  text-align: center;
  color: #333333;
  max-width: 700px;
  margin: 0 auto 3rem;
  font-size: 1.1rem;
  letter-spacing: 0.015em;
}

.skills-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.skill-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  padding: 1.75rem;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  border: 1px solid #eeeeee;
}

.skill-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  border-color: #dddddd;
}

.skill-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: #000000;
  opacity: 0.8;
}

.skill-header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  position: relative;
}

.skill-icon {
  width: 1.75rem;
  height: 1.75rem;
  color: #000000;
}

.skill-title {
  font-size: 1.35rem;
  font-weight: 700;
  color: #000000;
  letter-spacing: -0.025em;
  margin: 0;
}

.skill-item {
  margin: 0;
  padding: 0;
  color: #374151;
}

.skill-group {
  display: block;
  font-weight: 600;
  color: #000000;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
}

.skill-details {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.4rem;
}

.skill-tag {
  display: inline-block;
  padding: 0.35rem 0.8rem;
  background-color: #f5f5f5;
  color: #333333;
  border-radius: 30px;
  font-size: 0.85rem;
  transition: all 0.2s ease;
  border: 1px solid transparent;
}

.skill-tag:hover {
  background-color: #eeeeee;
  color: #000000;
  border-color: #dddddd;
}

.language-skills {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-top: 0.5rem;
}

.language-tag {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #f5f5f5;
  color: #333333;
  border-radius: 30px;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.language-tag:hover {
  background-color: #eeeeee;
  color: #000000;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .skills-section .skill-details {
    margin-left: 0.5rem;
  }
}

@media (min-width: 1024px) {
  .skills-container {
    gap: 2.5rem;
  }
  
  .skill-card {
    padding: 2rem;
  }
}
