.project-item {
  margin-bottom: 2em;
}

#projects h3 {
  margin-bottom: 0.5em;
  color: #000000;
}

#projects p {
  color: #333333;
}

/* Add more project-specific styles */
.projects-section {
  background-color: #fcfcfc;
}

.projects-section-title {
  color: #000000;
}

/* Project card specific styles */
.project-card h3 {
  color: #000000;
}

.project-card p {
  color: #333333;
}

.project-card ul li {
  color: #333333;
}

.project-card a {
  color: #333333;
}

.project-card a:hover {
  color: #000000;
}

.project-card .text-gray-600,
.project-card .text-gray-500,
.project-card .text-gray-700 {
  color: #333333 !important;
}

.project-card .text-gray-800,
.project-card .text-gray-900 {
  color: #1a1a1a !important;
}

/* Project card layout improvements */
.project-card {
  height: 100%;
  display: flex;
}

.project-card > div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.project-card .content-area {
  flex-grow: 1;
}

/* Ensure consistent spacing between content and footer */
.project-card .mt-auto {
  margin-top: 1.5rem;
  padding-top: 0.5rem;
}